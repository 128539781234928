import { gql, useQuery } from '@apollo/client'
import { Box, Heading, Text } from '@chakra-ui/react'

import { FC } from 'react'

import { ConversionsBySource } from './__generated__/ConversionsBySource'
import HorizontalBarChart, { DataType } from './charts/HorizontalBarChart'
import { BarChartSkeleton } from './charts/StackedBarChart'

/***
 *
 * Queries & Mutation
 *
 ***/
const conversionsBySourceQuery = gql`
	query ConversionsBySource {
		conversionBySource {
			source
			conversions
		}
	}
`

/***
 *
 * Conversions by Source Chart
 *
 ***/
const ConversionsBySourceChart: FC = () => {
	const { data, loading } = useQuery<ConversionsBySource>(conversionsBySourceQuery)

	if (loading || !data?.conversionBySource)
		return (
			<Box shadow='md' rounded='md' bg='white' w='100%' h='100%' p={8}>
				<BarChartSkeleton />
			</Box>
		)

	const conversionBySource = (data?.conversionBySource.filter(Boolean) ??
		[]) as unknown as DataType[]

	const totalConversions = conversionBySource.reduce(
		(prevVal, currentVal) => prevVal + (currentVal.conversions as number),
		0
	)

	return (
		<Box shadow='md' h='full' rounded='md' bg='white' p='3em' pt={4}>
			<Heading textAlign='center' color='gray.600' fontSize='md'>
				Booking performance by source
			</Heading>
			<Box h='22em' m='3em' position='relative'>
				<HorizontalBarChart
					data={conversionBySource}
					xAxisLabel='Last month conversions in %'
					yAxisLabel='Source types'
					getXAxisProperty={data => data.conversions as number}
					getYAxisProperty={data => data.source as number}
					xAxisExtent={[0, 100]}
				/>
				{totalConversions === 0 && (
					<Text
						fontSize='0.75em'
						fontWeight='bold'
						color='orange.600'
						position='absolute'
						top='50%'
						left='50%'
						transform={'translate(-50%, -50%)'}
					>
						No conversions made in last month
					</Text>
				)}
			</Box>
		</Box>
	)
}

export default ConversionsBySourceChart
