import * as d3 from 'd3'
import { styled } from 'linaria/react'

import { FC, useRef, useEffect } from 'react'

/***
 *
 * Interface & Type
 *
 ***/
export type DataType = { [index: string]: number | string }

interface Props {
	data: DataType[]
	getXAxisProperty: (data: DataType) => string
	getYAxisProperty: (data: DataType) => string
	getValueKey: (data: DataType) => number
	xAxisLabel?: string
	yAxisLabel?: string
}

/***
 *
 * Heatmap Component
 *
 ***/
const Heatmap: FC<Props> = props => {
	const {
		data,
		getXAxisProperty,
		getYAxisProperty,
		getValueKey,
		xAxisLabel = '',
		yAxisLabel = '',
	} = props

	const svgRef = useRef(null)
	const wrapperRef = useRef<HTMLDivElement>(null)
	const maxValue = Math.max(...(data.map(d => parseInt(d.value as string)) ?? [40]))

	useEffect(() => {
		if (!wrapperRef?.current) return

		const { width, height } = wrapperRef?.current?.getBoundingClientRect?.()
		const svg = d3.select(svgRef.current)

		const myGroups = Array.from(new Set(data.map(d => getXAxisProperty(d)))) as Iterable<string>
		const myVars = Array.from(new Set(data.map(d => getYAxisProperty(d)))) as Iterable<string>

		// Build X scales and axis:
		const x = d3.scaleBand().range([0, width]).domain(myGroups).padding(0.15)
		svg
			.append('g')
			.style('font-size', 15)
			.attr('transform', `translate(0, ${height})`)
			.call(d3.axisBottom(x).tickSize(0))
			.select('.domain')
			.remove()

		// Build Y scales and axis:
		const y = d3.scaleBand().range([height, 0]).domain(myVars).padding(0.15)
		svg
			.append('g')
			.style('font-size', 15)
			.call(d3.axisLeft(y).tickSize(0))
			.select('.domain')
			.remove()

		// Build color scale
		const colorScale = d3
			.scaleSequential()
			.interpolator(d3.interpolateHcl('#eefbe9', '#3f991a'))
			.domain([1, maxValue])

		// tooltip
		const tooltip = d3
			.select(wrapperRef?.current)
			.append('div')
			.attr('class', 'tooltip')
			.style('display', 'none')
			.style('position', 'absolute')
			.text('')

		// title text
		svg
			.append('text')
			.attr('transform', 'translate(' + width / 2 + ' ,' + (height + 50) + ')')
			.style('text-anchor', 'middle')
			.style('font-size', '0.7em')
			.style('fill', '#343a40')
			.style('font-weight', 700)
			.text(xAxisLabel)

		svg
			.append('text')
			.attr('transform', 'rotate(-90)')
			.attr('x', -(height / 2))
			.attr('y', width - (width + 45))
			.style('text-anchor', 'middle')
			.style('font-size', '0.7em')
			.style('fill', '#343a40')
			.style('font-weight', 700)
			.text(yAxisLabel)

		// color shade tile
		svg
			.append('div')
			.attr('height', 20)
			.attr('width', 100)
			.attr('fill', 'red')
			.attr('x', -(height / 2))
			.attr('y', width - (width + 45))

		// add the squares
		svg
			.selectAll()
			.data(data)
			.join('rect')
			.attr('x', d => x(getXAxisProperty(d)) as unknown as string)
			.attr('y', d => y(getYAxisProperty(d)) as unknown as string)
			.attr('rx', 8)
			.attr('ry', 8)
			.attr('width', x.bandwidth())
			.attr('height', y.bandwidth())
			.style('fill', d => colorScale(getValueKey(d)))
			.style('stroke-width', 4)
			.style('stroke', 'none')
			.style('opacity', 1)
			.on('mouseover', () => {
				tooltip.style('display', 'flex')
			})
			.on('mousemove', (event, d) => {
				tooltip.text(`${getValueKey(d)} leads.`)
				tooltip.style('top', event.pageY - 10 + 'px').style('left', event.pageX + 10 + 'px')
			})
			.on('mouseleave', () => {
				tooltip.style('display', 'none')
			})
	}, [])

	return (
		<StyledWrapper ref={wrapperRef}>
			<svg ref={svgRef}></svg>
		</StyledWrapper>
	)
}

const StyledWrapper = styled.div`
	width: 100%;
	height: 100%;

	svg {
		display: block;
		width: 100%;
		height: 100%;
		overflow: visible;
	}

	div.tooltip {
		background-color: #264653;
		border-radius: 0.5em;
		color: white;
		padding: 1em;
		align-items: center;
		justify-content: center;
		font-size: 0.75em;
	}
`

export default Heatmap
