import { gql, useQuery } from '@apollo/client'
import { Box, Heading } from '@chakra-ui/react'

import { FC } from 'react'

import { MyBookingConversions } from './__generated__/MyBookingConversions'
import LineChart, { TooltipConfig, DataType } from './charts/LineChart'
import { BarChartSkeleton } from './charts/StackedBarChart'

/***
 *
 * Queries & Mutation
 *
 ***/
const myBookingConversionQuery = gql`
	query MyBookingConversions {
		myBookingConversions {
			week
			conversions
		}
	}
`

/***
 *
 * Booking Conversions Chart
 *
 ***/
const BookingConversionsChart: FC = () => {
	const { data, loading } = useQuery<MyBookingConversions>(myBookingConversionQuery)

	if (loading || !data?.myBookingConversions)
		return (
			<Box shadow='md' rounded='md' bg='white' w='100%' h='100%' p={8}>
				<BarChartSkeleton />
			</Box>
		)

	const myBookingConversions = (data?.myBookingConversions.filter(Boolean) ??
		[]) as unknown as DataType[]

	const numberOfWeeks = myBookingConversions[myBookingConversions.length - 1].week

	const tooltipConfig: TooltipConfig = {
		setText: data => ({
			textOne: `Converted ${data?.conversions}% leads`,
			textTwo: `in week ${data?.week}`,
		}),
	}

	return (
		<Box shadow='md' h='full' rounded='md' bg='white' p={8} pt={4}>
			<Heading textAlign='center' color='gray.600' fontSize='md'>
				Your booking conversions
			</Heading>
			<Box h='22em' m={10}>
				<LineChart
					data={myBookingConversions}
					xAxisLabel={`Last ${numberOfWeeks} weeks`}
					yAxisLabel='Conversion rate in %'
					getXAxisProperty={data => data.week}
					getYAxisProperty={data => data.conversions}
					tooltipConfig={tooltipConfig}
				/>
			</Box>
		</Box>
	)
}

export default BookingConversionsChart
