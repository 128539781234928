import { Box, Grid, GridItem } from '@chakra-ui/react'

import { FC } from 'react'

import BookingConversionsChart from './BookingConversionsChart'
import ConversionPeriodChart from './ConversionPeriodChart'
import ConversionsBySourceChart from './ConversionsBySourceChart'
import LeadPipelineChart from './LeadPipelineChart'
import MedianResponseTimeChart from './MedianResponseTimeChart'
import MetricTiles from './MetricTiles'
import ResponseTimeBreakdownChart from './ResponseTimeBreakdownChart'

const AnalyticsBoard: FC = () => {
	const colSpanProp = { base: 4, md: 2, lg: 2, xl: 1 }

	return (
		<Box>
			<MetricTiles />
			<Grid templateColumns='1fr 1fr' gap={10} mt={10}>
				<GridItem colSpan={colSpanProp}>
					<LeadPipelineChart />
				</GridItem>
				<GridItem colSpan={colSpanProp}>
					<BookingConversionsChart />
				</GridItem>
				<GridItem colSpan={colSpanProp}>
					<ConversionsBySourceChart />
				</GridItem>
				<GridItem colSpan={colSpanProp}>
					<MedianResponseTimeChart />
				</GridItem>
				<GridItem colSpan={colSpanProp}>
					<ResponseTimeBreakdownChart />
				</GridItem>
				<GridItem colSpan={colSpanProp}>
					<ConversionPeriodChart />
				</GridItem>
			</Grid>
		</Box>
	)
}

export default AnalyticsBoard
