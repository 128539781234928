import { gql, useQuery } from '@apollo/client'
import { Box, Heading } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react'

import { FC } from 'react'

import { ResponseTimeBreakdown } from './__generated__/ResponseTimeBreakdown'
import { BarChartSkeleton } from './charts/StackedBarChart'

/***
 *
 * Queries & Mutation
 *
 ***/
const responseTimeBreakdownQuery = gql`
	query ResponseTimeBreakdown {
		responseTimeBreakdown {
			label
			percentage
		}
	}
`

/***
 *
 * Response Time Breakdown Chart
 *
 ***/
const ResponseTimeBreakdownChart: FC = () => {
	const { data, loading } = useQuery<ResponseTimeBreakdown>(responseTimeBreakdownQuery)

	if (loading || !data?.responseTimeBreakdown)
		return (
			<Box shadow='md' rounded='md' bg='white' w='100%' h='20em' p={8}>
				<BarChartSkeleton />
			</Box>
		)

	const responseTimeBreakdown = data?.responseTimeBreakdown.filter(Boolean) ?? []

	return (
		<Box shadow='md' h='full' rounded='md' bg='white' p={10} pt={4}>
			<Heading textAlign='center' color='gray.600' fontSize='md'>
				Response time breakdown
			</Heading>
			<Box m={10}>
				<TableContainer>
					<Table variant='simple'>
						<Thead>
							<Tr>
								<Th>First response</Th>
								<Th>% replies</Th>
							</Tr>
						</Thead>
						<Tbody>
							{responseTimeBreakdown.map((breakdown, index) => (
								<Tr fontSize='0.8em' key={index}>
									<Td>{breakdown?.label}</Td>
									<Td>{breakdown?.percentage} %</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
		</Box>
	)
}

export default ResponseTimeBreakdownChart
