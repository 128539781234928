import { Box, Flex } from '@chakra-ui/react'

import { FC } from 'react'

import Navbar from '~/components/Navbar'
import AnalyticsBoard from '~/components/analytics'
import { withPage } from '~/components/page'

const DashboardPage: FC = () => {
	return (
		<Flex h='full' direction='column'>
			<Navbar />
			<Box flexGrow={1} bg='gray.100' px='6em' py='3em'>
				<AnalyticsBoard />
			</Box>
		</Flex>
	)
}

export default withPage(DashboardPage, {
	public: false,
	name: 'Dashboard',
})
