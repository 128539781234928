import { gql, useQuery } from '@apollo/client'
import { Box, Heading } from '@chakra-ui/react'

import { FC } from 'react'

import { ConversionsPeriod } from './__generated__/ConversionsPeriod'
import Heatmap, { DataType } from './charts/Heatmap'
import { BarChartSkeleton } from './charts/StackedBarChart'

/***
 *
 * Queries & Mutation
 *
 ***/
const conversionPeriodQuery = gql`
	query ConversionsPeriod {
		conversionPeriod {
			day
			hour
			value
		}
	}
`

/***
 *
 * Conversions by Source Chart
 *
 ***/
const ConversionPeriodChart: FC = () => {
	const { data, loading } = useQuery<ConversionsPeriod>(conversionPeriodQuery)

	if (loading || !data?.conversionPeriod)
		return (
			<Box shadow='md' rounded='md' bg='white' w='100%' h='100%' p={8}>
				<BarChartSkeleton />
			</Box>
		)

	const conversionPeriod = (data?.conversionPeriod.filter(Boolean) ?? []) as unknown as DataType[]

	return (
		<Box shadow='md' h='full' rounded='md' bg='white' p={10} pt={4}>
			<Heading textAlign='center' color='gray.600' fontSize='md'>
				Busiest period for new conversations
			</Heading>
			<Box h='22em' m={10}>
				<Heatmap
					data={conversionPeriod}
					getXAxisProperty={data => data.hour as string}
					getYAxisProperty={data => data.day as string}
					getValueKey={data => data.value as number}
					xAxisLabel='Hours in day'
					yAxisLabel='Days'
				/>
			</Box>
		</Box>
	)
}

export default ConversionPeriodChart
