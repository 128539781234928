import { gql, useQuery } from '@apollo/client'
import { Box, Heading } from '@chakra-ui/react'

import { FC } from 'react'

import { MedianResponseTime } from './__generated__/MedianResponseTime'
import LineChart, { DataType, TooltipConfig } from './charts/LineChart'
import { BarChartSkeleton } from './charts/StackedBarChart'

/***
 *
 * Queries & Mutation
 *
 ***/
const medianResponseTimeQuery = gql`
	query MedianResponseTime {
		medianResponseTime {
			week
			median
		}
	}
`

/***
 *
 * Median Response Time Chart
 *
 ***/
const MedianResponseTimeChart: FC = () => {
	const { data, loading } = useQuery<MedianResponseTime>(medianResponseTimeQuery)

	if (loading || !data?.medianResponseTime)
		return (
			<Box shadow='md' rounded='md' bg='white' w='100%' h='20em' p={8}>
				<BarChartSkeleton />
			</Box>
		)

	const medianResponseTime = (data?.medianResponseTime.filter(Boolean) ??
		[]) as unknown as DataType[]

	const numberOfWeeks = medianResponseTime[medianResponseTime.length - 1].week

	const tooltipConfig: TooltipConfig = {
		setText: data => ({
			textOne: `Took ${data?.median} minutes to respond.`,
		}),
		width: 200,
	}

	return (
		<Box shadow='md' h='full' rounded='md' bg='white' p={'3em'} pt={4}>
			<Heading textAlign='center' color='gray.600' fontSize='md'>
				Median response time
			</Heading>
			<Box h='22em' m={'3em'}>
				<LineChart
					data={medianResponseTime}
					xAxisLabel={`Last ${numberOfWeeks} weeks`}
					yAxisLabel='Median response time in minutes'
					getXAxisProperty={data => data.week}
					getYAxisProperty={data => data.median}
					tooltipConfig={tooltipConfig}
					axisLabelConfig={{ gapY: 60 }}
				/>
			</Box>
		</Box>
	)
}

export default MedianResponseTimeChart
