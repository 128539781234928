import * as d3 from 'd3'
import { NumberValue } from 'd3'
import { styled } from 'linaria/react'

import { FC, useEffect, useRef } from 'react'

/***
 *
 * Interface & Types
 *
 ***/

const StyledWrapper = styled.div`
	width: 100%;
	height: 100%;

	svg {
		display: block;
		width: 100%;
		height: 100%;
		overflow: visible;
	}
`

export type DataType = { [index: string]: number }

export type TooltipConfig = {
	width?: number
	height?: number
	setText?: (data: DataType) => { textOne?: string; textTwo?: string }
}

export type StrokeConfig = {
	width?: string
	color?: string
}

type AxisLabelConfig = {
	gapY?: number
}

interface Props {
	data: DataType[]
	xAxisLabel?: string
	yAxisLabel?: string
	getXAxisProperty: (data: DataType) => number
	getYAxisProperty: (data: DataType) => number
	tooltipConfig?: TooltipConfig
	strokeConfig?: StrokeConfig
	axisLabelConfig?: AxisLabelConfig
}

/***
 *
 * Line Chart Component
 *
 ***/
const LineChart: FC<Props> = props => {
	const {
		data,
		xAxisLabel = 'X Axis',
		yAxisLabel = 'Y Axis',
		getXAxisProperty,
		getYAxisProperty,
	} = props

	const {
		height: tooltipHeight = 50,
		width: tooltipWidth = 140,
		setText,
	} = props.tooltipConfig || {}

	const { width: strokeWidth = '0.18em', color: strokeColor = '#f48c06' } = props.strokeConfig ?? {}

	const { gapY = 40 } = props.axisLabelConfig || {}

	const wrapperRef = useRef<HTMLDivElement>(null)
	const svgRef = useRef(null)

	useEffect(() => {
		if (!wrapperRef?.current) return

		const { width, height } = wrapperRef?.current?.getBoundingClientRect?.()

		// setting up svg
		const svg = d3.select(svgRef.current)

		// setting the scaling
		const xScaleExtent = [
			1,
			d3.max(data, layer => getXAxisProperty(layer)),
		] as Iterable<NumberValue>

		const xScale = d3.scaleLinear().domain(xScaleExtent).range([0, width])

		const yScaleExtent = [
			0,
			d3.max(data, layer => getYAxisProperty(layer)) || 2,
		] as Iterable<NumberValue>

		const yScale = d3.scaleLinear().domain(yScaleExtent).range([height, 0])

		const generatedScaledLine = d3
			.line<DataType>()
			.x(data => xScale(getXAxisProperty(data)))
			.y(data => yScale(getYAxisProperty(data)))

		// format y-axis values to integer properly
		const yAxisTicks = yScale.ticks().filter(tick => Number.isInteger(tick))
		const formatYAxis = d3.format('d')

		//setting up axes
		const xAxis = d3.axisBottom(xScale)
		const yAxis = d3.axisLeft(yScale).tickValues(yAxisTicks).tickFormat(formatYAxis)

		svg.append('g').call(xAxis).attr('transform', `translate(0, ${height})`)
		svg.append('g').call(yAxis)

		// draw line
		svg
			.selectAll('.line')
			.data([data])
			.join('path')
			.attr('d', data => generatedScaledLine(data))
			.attr('fill', 'none')
			.attr('stroke', strokeColor)
			.attr('stroke-width', strokeWidth)

		// title text
		svg
			.append('text')
			.attr('transform', 'translate(' + width / 2 + ' ,' + (height + 45) + ')')
			.style('text-anchor', 'middle')
			.style('font-size', '0.7em')
			.style('fill', '#343a40')
			.style('font-weight', 700)
			.text(xAxisLabel)

		svg
			.append('text')
			.attr('transform', 'rotate(-90)')
			.attr('x', -(height / 2))
			.attr('y', width - (width + gapY))
			.style('text-anchor', 'middle')
			.style('font-size', '0.7em')
			.style('fill', '#343a40')
			.style('font-weight', 700)
			.text(yAxisLabel)

		// tooltip
		const tooltip = svg.append('g').attr('class', 'tooltip').style('display', 'none')
		const bisectWeek = d3.bisector<DataType, unknown>(d => getXAxisProperty(d)).left

		tooltip.append('circle').attr('r', 5).style('fill', '#343a40')

		tooltip
			.append('rect')
			.style('fill', '#264653')
			.attr('class', 'tooltip')
			.attr('width', tooltipWidth)
			.attr('height', tooltipHeight)
			.attr('x', 10)
			.attr('y', -22)
			.attr('rx', 4)
			.attr('ry', 4)

		tooltip
			.append('text')
			.attr('class', 'tooltip-text-one')
			.attr('x', 22)
			.attr('y', -2)
			.style('fill', 'white')
			.style('font-size', '0.75em')

		tooltip
			.append('text')
			.attr('class', 'tooltip-text-two')
			.attr('x', 22)
			.attr('y', 18)
			.style('fill', 'white')
			.style('font-size', '0.75em')

		svg
			.append('rect')
			.attr('class', 'overlay')
			.attr('width', width)
			.attr('height', height)
			.style('fill', 'transparent')
			.on('mouseover', () => tooltip.style('display', null))
			.on('mouseout', () => tooltip.style('display', 'none'))
			.on('mousemove', event => {
				const x0 = xScale.invert(d3.pointer(event)[0])
				const i = bisectWeek(data, x0, 1)
				const d0 = data[i - 1]
				const d1 = data[i]
				const currentData = x0 - getXAxisProperty(d0) > getXAxisProperty(d0) - x0 ? d1 : d0
				const tooltipText = setText?.(currentData)
				tooltip.attr(
					'transform',
					'translate(' +
						xScale(getXAxisProperty(currentData)) +
						',' +
						yScale(getYAxisProperty(currentData)) +
						')'
				)
				tooltip.select('.tooltip-text-one').text(tooltipText?.textOne || '')
				tooltip.select('.tooltip-text-two').text(tooltipText?.textTwo || '')
			})
	}, [])

	return (
		<StyledWrapper ref={wrapperRef}>
			<svg ref={svgRef} />
		</StyledWrapper>
	)
}

export default LineChart
