import { gql, useQuery } from '@apollo/client'
import { Flex, Skeleton, Heading, Text, VStack, Grid, GridItem } from '@chakra-ui/react'
import { styled } from 'linaria/react'
import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi'

import React, { FC } from 'react'

import LeadStatsItem from '~/components/analytics/LeadStatsItem'

import { LeadMetrics } from './__generated__/LeadMetrics'
import { LeadReponseTime } from './__generated__/LeadReponseTime'

/***
 *
 * Queries & Mutations
 *
 ***/
const getLeadMetricsQuery = gql`
	query LeadMetrics {
		leadMetrics {
			all_leads {
				count
				percentage
			}

			uncontacted_leads {
				count
				percentage
			}

			missed_alarm_leads {
				count
				percentage
			}

			tco_assigned_leads {
				count
				percentage
			}

			dentists_assigned_leads {
				count
				percentage
			}
		}
	}
`

const getLeadResponseTimeQuery = gql`
	query LeadReponseTime {
		leadResponseTime {
			seconds
			percentage
		}
	}
`

/***
 *
 * Metric Tiles Component
 *
 ***/
const MetricTiles: FC = () => {
	const leadMetricsQuery = useQuery<LeadMetrics>(getLeadMetricsQuery)
	const leadResponseTimeQuery = useQuery<LeadReponseTime>(getLeadResponseTimeQuery)

	const TileLoader = <Skeleton flex={1} minW='10em' h='8em' rounded='md' px='2em' py='1em' />
	const InfoText = (
		<Text fontSize='sm' color='gray.400'>
			vs previous month
		</Text>
	)

	const percentageChangeInLeadCreation =
		leadMetricsQuery.data?.leadMetrics.all_leads.percentage ?? 0

	const percentageChangeInUncontactedLeads =
		leadMetricsQuery.data?.leadMetrics.uncontacted_leads?.percentage ?? 0

	const percentageChangeInMissedAlarmLeads =
		leadMetricsQuery.data?.leadMetrics.missed_alarm_leads?.percentage ?? 0

	const percentageChangeInLeadResTime = leadResponseTimeQuery.data?.leadResponseTime.percentage ?? 0

	const formatResponseTime = (seconds?: number) => {
		if (!seconds) return '0'

		let label = ''

		if (seconds < 60) {
			label = `${seconds} sec`
		} else if (seconds >= 60 && seconds < 3600) {
			label = `${(seconds / 60).toFixed(0)} min`
		} else {
			label = `${(seconds / 3600).toFixed(0)} hours`
		}

		return label
	}

	return (
		<Grid templateColumns='1fr 1fr 1fr 1fr' gap={10}>
			<LeadStatsItem
				filters={{
					start: 0,
					limit: 1,
					sort: 'created_at:desc',
					where: {
						role: 'reception',
						types: ['consult_booked'],
						dentally_appointment_id: false,
						archive: false,
					},
				}}
				title='Consults Booked - Appt ID Missing'
			/>
			<LeadStatsItem
				title='Consults Completed without TX Plan ID'
				filters={{
					start: 0,
					limit: 1,
					sort: 'created_at:desc',
					where: {
						role: 'tco',
						types: ['TX_given', 'appointment_pending'],
						treatment_plan_id: false,
						archive: false,
					},
				}}
			/>
			<GridItem colSpan={{ base: 4, md: 2, lg: 2, xl: 1 }}>
				{leadMetricsQuery.loading ? (
					TileLoader
				) : (
					<Styled.Stack shadow='md' rounded='md'>
						<Heading color='gray.600' fontSize='md'>
							Leads without TCO
						</Heading>
						<Styled.Text color='gray.700'>
							{leadMetricsQuery.data?.leadMetrics.tco_assigned_leads?.count || 0}
						</Styled.Text>
					</Styled.Stack>
				)}
			</GridItem>
			<GridItem colSpan={{ base: 4, md: 2, lg: 2, xl: 1 }}>
				{leadMetricsQuery.loading ? (
					TileLoader
				) : (
					<Styled.Stack shadow='md' rounded='md'>
						<Heading color='gray.600' fontSize='md'>
							Leads without Dentist
						</Heading>
						<Styled.Text color='gray.700'>
							{leadMetricsQuery.data?.leadMetrics.dentists_assigned_leads?.count || 0}
						</Styled.Text>
					</Styled.Stack>
				)}
			</GridItem>
			<GridItem colSpan={{ base: 4, md: 2, lg: 2, xl: 1 }}>
				{leadMetricsQuery.loading ? (
					TileLoader
				) : (
					<Styled.Stack shadow='md' rounded='md'>
						<Heading color='gray.600' fontSize='md'>
							Leads Total
						</Heading>
						<Styled.Text color='gray.700'>
							{leadMetricsQuery.data?.leadMetrics.all_leads.count}
						</Styled.Text>
						<Flex align='center' gap={2}>
							{percentageChangeInLeadCreation > 0 ? (
								<BiTrendingUp size='1.5em' color='green' />
							) : (
								<BiTrendingDown size='1.5em' color='red' />
							)}
							<Text fontWeight='bold' color={percentageChangeInLeadCreation > 0 ? 'green' : 'red'}>
								{`${Math.abs(percentageChangeInLeadCreation)} %`}
							</Text>
							{InfoText}
						</Flex>
					</Styled.Stack>
				)}
			</GridItem>

			<GridItem colSpan={{ base: 4, md: 2, lg: 2, xl: 1 }}>
				{leadResponseTimeQuery.loading ? (
					TileLoader
				) : (
					<Styled.Stack shadow='md' rounded='md'>
						<Heading color='gray.600' fontSize='md'>
							Median Response Time
						</Heading>
						<Styled.Text color='gray.700'>
							{formatResponseTime(leadResponseTimeQuery.data?.leadResponseTime.seconds)}
						</Styled.Text>
						<Flex align='center' gap={2}>
							{percentageChangeInLeadResTime > 0 ? (
								<BiTrendingUp size='1.5em' color='red' />
							) : (
								<BiTrendingDown size='1.5em' color='green' />
							)}
							<Text fontWeight='bold' color={percentageChangeInLeadResTime > 0 ? 'red' : 'green'}>
								{`${Math.abs(percentageChangeInLeadResTime)} %`}
							</Text>
							{InfoText}
						</Flex>
					</Styled.Stack>
				)}
			</GridItem>

			<GridItem colSpan={{ base: 4, md: 2, lg: 2, xl: 1 }}>
				{leadMetricsQuery.loading ? (
					TileLoader
				) : (
					<Styled.Stack shadow='md' rounded='md'>
						<Heading color='gray.600' fontSize='md'>
							Uncontacted Leads
						</Heading>
						<Styled.Text color='gray.700'>
							{leadMetricsQuery.data?.leadMetrics.uncontacted_leads?.count}
						</Styled.Text>
						<Flex align='center' gap={2}>
							{percentageChangeInUncontactedLeads > 0 ? (
								<BiTrendingUp size='1.5em' color='red' />
							) : (
								<BiTrendingDown size='1.5em' color='green' />
							)}
							<Text
								fontWeight='bold'
								color={percentageChangeInUncontactedLeads > 0 ? 'red' : 'green'}
							>
								{`${Math.abs(percentageChangeInUncontactedLeads)} %`}
							</Text>
							{InfoText}
						</Flex>
					</Styled.Stack>
				)}
			</GridItem>

			<GridItem colSpan={{ base: 4, md: 2, lg: 2, xl: 1 }}>
				{leadMetricsQuery.loading ? (
					TileLoader
				) : (
					<Styled.Stack shadow='md' rounded='md'>
						<Heading color='gray.600' fontSize='md'>
							Missed Alarm
						</Heading>
						<Styled.Text color='gray.700'>
							{leadMetricsQuery.data?.leadMetrics.missed_alarm_leads?.count}
						</Styled.Text>
						<Flex align='center' gap={2}>
							{percentageChangeInMissedAlarmLeads > 0 ? (
								<BiTrendingUp size='1.5em' color='red' />
							) : (
								<BiTrendingDown size='1.5em' color='green' />
							)}
							<Text
								fontWeight='bold'
								color={percentageChangeInMissedAlarmLeads > 0 ? 'red' : 'green'}
							>
								{`${Math.abs(percentageChangeInMissedAlarmLeads)} %`}
							</Text>
							{InfoText}
						</Flex>
					</Styled.Stack>
				)}
			</GridItem>
		</Grid>
	)
}

const Styled = {
	Stack: styled(VStack)`
		align-items: center;

		flex: 1;
		background-color: white;
		padding: 1em 2em;
	`,

	Text: styled(Text)`
		font-size: 2em;
	`,
}

export default MetricTiles
