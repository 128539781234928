import * as d3 from 'd3'
import { NumberValue } from 'd3'
import { styled } from 'linaria/react'

import { FC, useRef, useEffect } from 'react'

/***
 *
 * Interface & Type
 *
 ***/
export type DataType = { [index: string]: string | number }

interface Props {
	data: DataType[]
	xAxisLabel?: string
	yAxisLabel?: string
	xAxisExtent?: Iterable<d3.NumberValue>
	getXAxisProperty: (data: DataType) => number
	getYAxisProperty: (data: DataType) => number
}

/***
 *
 * Horizontal Bar Chart Component
 *
 ***/
const HorizontalBarChart: FC<Props> = props => {
	const {
		data,
		xAxisLabel = '',
		yAxisLabel = '',
		getXAxisProperty,
		getYAxisProperty,
		xAxisExtent,
	} = props

	const svgRef = useRef(null)
	const wrapperRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (!wrapperRef?.current) return

		const { width, height } = wrapperRef?.current?.getBoundingClientRect?.()
		const svg = d3.select(svgRef.current)

		// extent for min max values
		const calcExtent = [
			0,
			d3.max(data, data => getXAxisProperty(data) as NumberValue),
		] as Iterable<NumberValue>

		// Draw axis
		const xAxis = d3
			.scaleLinear()
			.domain(xAxisExtent ?? calcExtent)
			.range([0, width])
		svg
			.append('g')
			.attr('transform', `translate(0, ${height})`)
			.call(d3.axisBottom(xAxis))
			.selectAll('text')

		const yAxis = d3
			.scaleBand()
			.range([0, height])
			.domain(data.map(d => getYAxisProperty(d)) as Iterable<string>)
			.padding(0.4)

		svg.append('g').call(d3.axisLeft(yAxis))

		// Draw bars
		svg
			.selectAll('myRect')
			.data(data)
			.join('rect')
			.attr('x', xAxis(0))
			.attr('y', d => yAxis(getYAxisProperty(d).toString()) as unknown as string)
			.attr('width', d => xAxis(getXAxisProperty(d)))
			.attr('height', yAxis.bandwidth())
			.attr('fill', '#69b3a2')

		// title text
		svg
			.append('text')
			.attr('transform', 'translate(' + width / 2 + ' ,' + (height + 60) + ')')
			.style('text-anchor', 'middle')
			.style('font-size', '0.7em')
			.style('fill', '#343a40')
			.style('font-weight', 700)
			.text(xAxisLabel)

		svg
			.append('text')
			.attr('transform', 'rotate(-90)')
			.attr('x', -(height / 2))
			.attr('y', width - (width + 70))
			.style('text-anchor', 'middle')
			.style('font-size', '0.7em')
			.style('fill', '#343a40')
			.style('font-weight', 700)
			.text(yAxisLabel)
	}, [])

	return (
		<StyledWrapper ref={wrapperRef}>
			<svg ref={svgRef}></svg>
		</StyledWrapper>
	)
}

const StyledWrapper = styled.div`
	width: 100%;
	height: 100%;

	svg {
		display: block;
		width: 100%;
		height: 100%;
		overflow: visible;
	}
`

export default HorizontalBarChart
