import { HStack, Skeleton } from '@chakra-ui/react'
import * as d3 from 'd3'
import { BaseType, NumberValue, Selection } from 'd3'
import { styled } from 'linaria/react'

import { FC, useRef, useEffect, useState } from 'react'

/***
 *
 * Interface & Type
 *
 ***/
export type DataType = { [index: string]: string | number }
type ColorType = { [index: string]: string }
type AxisType = (selection: Selection<BaseType, unknown, null, undefined>) => void
export type SetTooltipText = (
	event: React.MouseEvent<SVGSVGElement, MouseEvent>,
	layerData: d3.SeriesPoint<{
		[key: string]: number
	}>
) => string

interface Props {
	data: DataType[]
	allKeys: string[]
	colorsConfig: ColorType
	getXAxisLabel: (data: DataType) => string
	setTooltipText?: SetTooltipText
	xAxisLabel?: string
	yAxisLabel?: string
}

/***
 *
 * Stacked Bar Chart Component
 *
 ***/
const StackedBarChart: FC<Props> = props => {
	const {
		data,
		allKeys,
		colorsConfig,
		getXAxisLabel,
		setTooltipText,
		xAxisLabel = '',
		yAxisLabel = '',
	} = props

	const svgRef = useRef(null)
	const wrapperRef = useRef<HTMLDivElement>(null)

	const keys = allKeys
	const colors = colorsConfig

	useEffect(() => {
		if (!wrapperRef?.current) return

		const { width, height } = wrapperRef?.current?.getBoundingClientRect?.()
		const svg = d3.select(svgRef.current)

		// stacks / layers
		const stackGenerator = d3.stack().keys(keys)
		const layers = stackGenerator(data as Iterable<{ [index: string]: number }>)
		const extent = [
			0,
			d3.max(layers, layer => d3.max(layer, sequence => sequence[1])),
		] as Iterable<NumberValue>

		// scales
		const xScale = d3
			.scaleBand()
			.domain(data.map(d => getXAxisLabel(d as DataType)))
			.range([0, width])
			.padding(0.2)

		const yScale = d3.scaleLinear().domain(extent).range([height, 0])

		// format y-axis values to integer properly
		const yAxisTicks = yScale.ticks().filter(tick => Number.isInteger(tick))
		const formatYAxis = d3.format('d')

		// tooltip
		const tooltip = d3
			.select(wrapperRef?.current)
			.append('div')
			.attr('class', 'tooltip')
			.style('display', 'none')
			.style('position', 'absolute')
			.text('')

		// rendering
		svg
			.selectAll('.layer')
			.data(layers)
			.join('g')
			.attr('class', 'layer')
			.attr('fill', layer => colors[layer.key])
			.selectAll('rect')
			.data(layer => layer)
			.join('rect')
			.attr('x', sequence => {
				const sequenceData = sequence.data as unknown
				const day = getXAxisLabel(sequenceData as DataType)

				return xScale(day)?.toString() as string
			})
			.attr('width', xScale.bandwidth())
			.attr('y', sequence => yScale(sequence[1]))
			.attr('height', sequence => yScale(sequence[0]) - yScale(sequence[1]))
			.on('mouseover', () => {
				tooltip.style('display', 'flex')
			})
			.on('mouseleave', () => {
				tooltip.style('display', 'none')
			})
			.on('mousemove', (event, layerData) => {
				const text = setTooltipText?.(event, layerData) ?? ''

				tooltip.style('top', event.pageY - 10 + 'px').style('left', event.pageX + 10 + 'px')
				tooltip.text(text)
			})

		// title text
		svg
			.append('text')
			.attr('transform', 'translate(' + width / 2 + ' ,' + (height + 45) + ')')
			.style('text-anchor', 'middle')
			.style('font-size', '0.7em')
			.style('fill', '#343a40')
			.style('font-weight', 700)
			.text(xAxisLabel)

		svg
			.append('text')
			.attr('transform', 'rotate(-90)')
			.attr('x', -(height / 2))
			.attr('y', width - (width + 40))
			.style('text-anchor', 'middle')
			.style('font-size', '0.7em')
			.style('fill', '#343a40')
			.style('font-weight', 700)
			.text(yAxisLabel)

		// axes
		const xAxis = d3.axisBottom(xScale) as unknown
		svg
			.select('.x-axis')
			.attr('transform', `translate(0, ${height})`)
			.call(xAxis as AxisType)

		const yAxis = d3.axisLeft(yScale).tickValues(yAxisTicks).tickFormat(formatYAxis) as unknown
		svg.select('.y-axis').call(yAxis as AxisType)
	}, [data, colors])

	return (
		<StyledWrapper ref={wrapperRef}>
			<svg ref={svgRef}>
				<g className='x-axis' />
				<g className='y-axis' />
			</svg>
		</StyledWrapper>
	)
}

const StyledWrapper = styled.div`
	width: 100%;
	height: 100%;

	svg {
		display: block;
		width: 100%;
		height: 100%;
		overflow: visible;
	}

	div.tooltip {
		height: 2em;
		background-color: #264653;
		border-radius: 0.5em;
		color: white;
		padding: 0 1em 0 1em;
		align-items: center;
		justify-content: center;
		font-size: 0.75em;
	}
`
export const BarChartSkeleton: FC = () => {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const [numberOfBars, setNumberOfBars] = useState(0)

	const heightConfig = [
		'60%',
		'100%',
		'80%',
		'30%',
		'60%',
		'100%',
		'80%',
		'30%',
		'60%',
		'100%',
		'80%',
		'30%',
	]

	useEffect(() => {
		if (!wrapperRef?.current) return

		const { width } = wrapperRef?.current?.getBoundingClientRect?.()
		const widthInEm = width / 16
		const numberOfBars = Math.round(widthInEm / 2 / 2)

		setNumberOfBars(numberOfBars)
	}, [])

	return (
		<HStack ref={wrapperRef} w='full' h='full' align='end' justify='center' spacing='1em'>
			{heightConfig.slice(0, numberOfBars).map((h, idx) => (
				<Skeleton key={idx} w='2em' h={h} rounded='md' />
			))}
		</HStack>
	)
}

export default StackedBarChart
