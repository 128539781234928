import { gql, useQuery } from '@apollo/client'
import { Box, Flex, Heading } from '@chakra-ui/react'

import { FC } from 'react'

import { LeadPipeline } from './__generated__/LeadPipeline'
import StackedBarChart, {
	BarChartSkeleton,
	DataType,
	SetTooltipText,
} from './charts/StackedBarChart'

/***
 *
 * Queries & Mutation
 *
 ***/
const leadPipelineQuery = gql`
	query LeadPipeline {
		leadPipeline {
			day
			total
			call
			email
			live_chat
			instagram
		}
	}
`

/***
 *
 * Lead Pipeline Component
 *
 ***/
const LeadPipelineChart: FC = () => {
	const { data, loading } = useQuery<LeadPipeline>(leadPipelineQuery)

	if (loading || !data?.leadPipeline)
		return (
			<Box alignItems='center' shadow='md' rounded='md' bg='white' w='100%' h='20em' p={8}>
				<BarChartSkeleton />
			</Box>
		)

	const leadPipelineData = data?.leadPipeline.filter(Boolean) as unknown as DataType[]

	const allKeys = Object.keys(leadPipelineData[0]).filter(
		key => !['day', '__typename'].includes(key)
	)

	const colorList = ['#ffb703', '#00b4d8', '#F56040', '#2a9d8f', '#84a98c']
	const colorsConfig = Object.fromEntries(allKeys.map((key, idx) => [key, colorList[idx]]))

	const setTooltipText: SetTooltipText = (event, layerData) => {
		const target = event.target as SVGSVGElement
		const layerColor = target?.parentElement?.attributes?.getNamedItem('fill')?.value
		const sourceType = Object.keys(colorsConfig).find(key => colorsConfig[key] === layerColor) ?? ''
		const leadCount = layerData.data[sourceType]
		const leadText = leadCount > 1 ? 'leads' : 'lead'

		return `${leadCount} ${leadText}`
	}

	return (
		<Box shadow='md' rounded='md' bg='white' p={8} pt={4}>
			<Heading textAlign='center' color='gray.600' fontSize='md'>
				Lead pipeline
			</Heading>
			<Box h='20em' m={8}>
				<StackedBarChart
					data={leadPipelineData}
					allKeys={allKeys}
					colorsConfig={colorsConfig}
					xAxisLabel='Days of current month'
					yAxisLabel='Lead count'
					setTooltipText={setTooltipText}
					getXAxisLabel={data => {
						const pipelineData = data as { day: string }
						return pipelineData.day
					}}
				/>
			</Box>
			<Flex w='full' justify='center' gap={10} pt={10}>
				{/* {Object.entries(colorsConfig).map(([key, color]) => (
					<Flex gap={2} align='center' key={key}>
						<Text color='gray.600' fontSize='md'>
							{key.replace('_', ' ')}
						</Text>
						<Box rounded='full' h={4} w={4} bg={color}></Box>
					</Flex>
				))} */}
			</Flex>
		</Box>
	)
}

export default LeadPipelineChart
