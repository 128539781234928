import { gql, useQuery } from '@apollo/client'
import { GridItem, Heading, Skeleton, Text, VStack } from '@chakra-ui/react'
import { styled } from 'linaria/react'

import React from 'react'

import { LeadsList, LeadsListVariables } from '~/components/kanban/__generated__/LeadsList'

export const leadStatsQuery = gql`
	query LeadsStatsQuery($sort: String, $start: Int, $limit: Int, $where: JSON) {
		leadsList(sort: $sort, start: $start, limit: $limit, where: $where) {
			total
		}
	}
`

const Styled = {
	Stack: styled(VStack)`
		align-items: center;

		flex: 1;
		background-color: white;
		padding: 1em 2em;
	`,

	Text: styled(Text)`
		font-size: 2em;
	`,
}

interface LeadStatsItemProps {
	title: string
	filters: LeadsListVariables
}

const LeadStatsItem = ({ title, filters }: LeadStatsItemProps) => {
	const { data, loading } = useQuery<LeadsList, LeadsListVariables>(leadStatsQuery, {
		notifyOnNetworkStatusChange: true,
		variables: {
			...filters,
		},
	})
	const TileLoader = <Skeleton flex={1} minW='10em' h='8em' rounded='md' px='2em' py='1em' />
	if (loading) {
		return TileLoader
	}

	return (
		<GridItem colSpan={{ base: 4, md: 2, lg: 2, xl: 1 }}>
			<Styled.Stack
				shadow='md'
				rounded='md'
				sx={{
					display: 'flex',
					padding: '1em !important',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Heading color='gray.600' fontSize='md'>
					{title}
				</Heading>
				<Styled.Text color='gray.700'>{data?.leadsList.total}</Styled.Text>
			</Styled.Stack>
		</GridItem>
	)
}

export default LeadStatsItem
